@use '../../../../../styles/breakpoints';
@use '../../../../../styles/variables';

.container {
  align-items: center;
  display: flex;
  justify-content: flex-start;
  left: 20px;
  max-width: 330px;
  top: 35px;
  width: 100%;
}

.infoPod {
  align-items: center;
  background-color: rgba(255, 255, 255, 37%);
  border-radius: 8px;
  color: var(--modal-color);
  display: flex;
  font-size: 14px;
  font-weight: 600;
  justify-content: space-evenly;
  line-height: 19px;
  max-width: 300px;
  padding: 14px 20px;
  width: 100%;
}
