@use '../../../../../styles/breakpoints';
@use '../../../../../styles/variables';

.container {
  align-items: end;
  display: flex;
  flex-direction: column;
  height: 50px;
  position: fixed;
  right: 29px;
  top: 21px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    bottom: 1.5rem;
    right: 20px;
    top: auto;
  }
}

.icon {
  align-items: center;
  border-radius: 99999px;
  display: flex;
  justify-content: center;
  position: absolute;
  z-index: 2;

  path {
    fill: var(--menu-button-color);
  }
}

.buttonContainer {
  flex-direction: column;
  height: 35px;
  width: 142px;

  @media (max-width: breakpoints.$breakpoint-sm) {
    right: 15px;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    right: 0;
  }
}

.button {
  background-color: var(--menu-button-background);
  border-radius: 5px;
  border-style: none;
  color: var(--menu-button-color);
  cursor: pointer;
  font-size: 14px;
  font-weight: 600;
  margin-bottom: 5px;
  padding: 10px 15px;
  text-align: center;
  text-decoration: none;

  @media (max-width: breakpoints.$breakpoint-sm) {
    margin-bottom: 10px;
    padding: 15px;
  }
}
