@use '../../../../../styles/variables';
@use '../../../../../styles/breakpoints';

/* stylelint-disable-line unit-no-unknown */
$arrow-height: 90%;
$portrait-height: 85%;
$landscape-height: 75%;

.container {
  background-color: var(--carousel-background);
  height: 100%;
  opacity: 0.9;
  position: absolute;
  right: 0;
  width: 18%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    width: 100%;
    z-index: 3;
  }
}

.item {
  align-items: center;
  border-bottom: 1px solid variables.$grey;
  color: var(--carousel-title-color);
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  padding: 10px 0;
}

.active {
  font-weight: 700;
}

.closeContainer {
  cursor: pointer;
  display: flex;
  height: 26px;
  justify-content: flex-end;
  padding: 20px;
  width: 100%;
}

.imageContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  margin: 0 40px;

  > img {
    height: 100%;
    width: 100%;
  }
  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    justify-content: flex-start;
    width: 95%;

    > img {
      height: auto;
      width: auto;
    }
  }
}

.carousel.carousel {
  height: 80%;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    height: $landscape-height;
  }
}

.arrows {
  align-items: center;
  border-radius: 10px;
  bottom: 0;
  display: flex;
  flex-direction: column;
  height: $arrow-height;
  justify-content: space-between;
  opacity: 0.8;
  pointer-events: none;
  position: absolute;
  width: 100%;

  svg {
    cursor: pointer;
    pointer-events: all;
  }

  @media (max-width: breakpoints.$breakpoint-sm) {
    bottom: 5px;
    height: $portrait-height;
    z-index: 6;
  }

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    height: $landscape-height;
  }
}
