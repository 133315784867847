@use '../../../../../styles/variables';
@use '../../../../../styles/breakpoints';

.menuContainer {
  align-items: center;
  background-color: var(--carousel-background);
  border-radius: 10px;
  bottom: 1.5rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 22.5%;
  position: absolute;
  right: 22.5%;

  @media (max-width: breakpoints.$breakpoint-sm) {
    flex-direction: row;
    left: 20px;
  }
}

.carousel {
  width: 100%;
}

.divider {
  border-bottom: 0.7px solid #d2d2d2;
  transform: translateX(14px);
  width: calc(100% - 28px);
}

.iconContainer {
  align-items: center;
  display: flex;
  justify-content: center;
  width: 100%;
}

.menuBar {
  display: flex;
  height: 50px;
  justify-content: space-around;
  width: 100%;
}

.buttonContainer {
  align-items: center;
  display: flex;
  justify-content: center;
}

.buttonItem {
  align-items: center;
  color: var(--menu-button-color);
  cursor: pointer;
  display: flex;
  justify-content: center;
  transition: 0.2s background-color;

  &:hover:not(.menuActive) {
    color: var(--menu-icon-color-hover);
    transition: 0.2s color;
  }
}

.menuActive {
  color: var(--menu-icon-color-active);
}

.autoRotate,
.fullscreen {
  margin-bottom: 0;

  path {
    fill: var(--menu-button-color);
  }
}
