@use '../../../../../../../styles/variables';

.container {
  display: flex;
  flex-direction: column;
  justify-items: center;
}

.floorPlanControls {
  align-items: center;
  background-color: rgba(255, 255, 255, 37%);
  border-radius: 7px;
  display: flex;
  flex-direction: column;
  gap: 10px;
  margin-bottom: 10px;
  padding: 5px;
  width: 30px;
}

.floorPlanControlsIcons {
  stroke: white;
  text-align: center;
}

.floorPlanControlsIcon {
  cursor: pointer;
  margin-bottom: 5px;
}

.sliderContainer {
  height: 100%;
  padding: 30px 0;

  @media (max-width: variables.$breakpoint-sm) {
    padding: 20px 0;
  }
}
