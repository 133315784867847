@use '../../../styles/breakpoints';

.container {
  display: flex;
}

.bannerPosition {
  right: 110px;
  top: 30px;
}

.bannerPortrait {
  bottom: 90px;
  display: flex;
  justify-content: center;
  width: 100%;
}
