@use '../../../styles/variables';

.bannerPortrait {
  display: none;

  @media (max-width: variables.$breakpoint-sm) and (orientation: portrait) {
    bottom: 90px;
    display: flex;
    justify-content: center;
    width: 100%;
  }
}
