@use '../../../../../styles/breakpoints';
@use '../../../../../styles/variables';

.container {
  align-items: center;
  background-color: var(--menu-background);
  border-radius: 8px;
  bottom: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  left: 0;
  max-height: 350px;
  max-width: 250px;
  position: absolute;
  z-index: 2;

  @media (max-width: breakpoints.$breakpoint-sm) and (orientation: landscape) {
    max-height: 60vh;
  }
}

.carousel {
  height: 100%;
  margin-bottom: 5px;
  width: 100%;
}

.item {
  align-items: center;
  color: variables.$dark-grey;
  cursor: pointer;
  display: flex;
  justify-content: flex-start;
  margin: 0;
  padding: 10px 10px 10px 20px;
  width: 100%;
}

.active {
  background-color: rgb(77, 77, 77);
  font-weight: 700;
}

.title {
  color: var(--text-color);
}

.pillContainer {
  margin: 15px 20px;
}
