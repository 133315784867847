.floorPlan {
  display: flex;
  gap: 10px;
  left: 20px;
  position: absolute;
  top: 20px;
  z-index: 0;
}

.banner {
  position: relative;
}

.rightSide {
  display: flex;
  flex-direction: column;
  gap: 10px;
}
